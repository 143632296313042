// i18next-extract-mark-ns-start products-fearce-processing

import { FEARCE_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductFearcePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							FEARCE is not just a pre-processing tool, but also
							contains its own linear solver. Ideal for thermal
							analysis, the solver has been benchmarked and proven to
							be as accurate as and faster than the major third-party
							alternatives. The solver can also be used for a range of
							other solutions including displacement prediction,
							stress prediction, modal contribution, forced response
							analysis, eigenvalue calculation and model reduction.
						</p>
						<p>
							FEARCE includes a large number of post-processing tools,
							many of which have been developed for the specific needs
							of powertrain and vehicle analysis.
						</p>
						<h2>Linear analysis</h2>
						<p>
							For linear analysis, load-case results can be combined
							and factored to create more complex conditions – this
							can also include calculations to account for the
							improvement of bolt loads in an assembly.
						</p>
						<h2>Non-linear analysis</h2>
						<p>
							In non-linear solutions analysis, nodal stress and
							strain histories can be identified and plotted.
						</p>
						<h2>Powertrain analysis</h2>
						<p>
							For powertrain analysis, FEARCE can perform bore-,
							bearing- and valve-distortion calculations, and produce
							suitable plots of deformations, harmonics, alignment and
							ring conformability.
						</p>
						<h2>Vehicle analysis</h2>
						<p>
							For vehicle analysis, FEARCE can carry out design
							sensitivity analyses, perform quick checks for model
							assurance and provide transient difference plots between
							models undergoing crash analysis.
						</p>
						<p>
							FEARCE also provides extensive durability and noise,
							vibration and harshness (NVH) modules suitable for any
							type of analysis.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>Built-in linear solver</li>
							<li>
								Solution set-up for any major third-party finite
								element (FE) package
							</li>
							<li>Large range of post-processing tools</li>
							<li>Load-case combination and factoring</li>
							<li>Stress and strain history plotting</li>
							<li>Thermocouple and strain-gauge predictions</li>
							<li>Bore-, bearing- and valve-distortion plots</li>
							<li>Design sensitivity analysis</li>
							<li>Durability calculations</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{FEARCE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductFearcePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-fearce-processing", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/fearce-hero.jpg" }) {
		...imageBreaker
	}
}
`;
